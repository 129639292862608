<template>
  <a-auto-complete
    v-model:value="valueState"
    placeholder="Grup Toko Curah"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    :show-arrow="true"
  >
    <template #dataSource>
      <a-select-option v-for="s in suggestValue" :key="s.value" :label="s.value">
        <span v-html="highlight(s.value)"></span>
        <!-- <span>{{ d.value }}</span> -->
      </a-select-option>
    </template>
  </a-auto-complete>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)
    const suggestValue = reactive([])

    const fetchData = (q = null) => {
      // data must be like data.value = [{id, name},{id, name}]
      suggestValue.splice(0)
      apiClient.get('/api/filter/toko-curah', { params: { q } }).then(response => {
        data.value = response.data
        data.value.forEach((value, index) => {
          if (value.group_customer != null) {
            if ( suggestValue.length <= 0) { suggestValue.push({value : value.group_customer.toLowerCase()}) }
            else {
              var isExist = true
              var countExist = 0
              suggestValue.forEach((val, i) => {
                if (value.group_customer.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                else { isExist = true }
              });
              if (isExist === false) {
                suggestValue.push({value : value.group_customer.toLowerCase()})
                if ((suggestValue.length - 1) !== countExist) { suggestValue.pop() }
              }
            }
          }
        });

      })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = value => {
      findText.value = value
      fetchData(value)
    }

    const highlight = value => {
      if (value === undefined) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    return { data, valueState: useVModel(props, 'value'), findText, onSearch, highlight, suggestValue }
  },
}
</script>

<style></style>
